import React from "react";
import Header from "./components/Header";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
const Cgu = () => {
  return (
    <div className="containerMentionL">
      <Header />
      <Navigation />
      <h1>Conditions Générales d'Utilisation (CGU)</h1>

      <h2>1. Présentation de l'application</h2>
      <p>
        Cette application a pour but d'aider les utilisateurs à stabiliser ou
        perdre du poids en leur fournissant des outils et des conseils
        personnalisés. Elle ne remplace en aucun cas un suivi médical ou
        nutritionnel professionnel.
      </p>

      <h2>2. Accès et utilisation de l'application</h2>
      <p>
        L'application est accessible à toute personne majeure. En utilisant
        cette application, vous acceptez de fournir des informations exactes et
        à jour, et de ne pas utiliser l'application à des fins illégales.
      </p>

      <h2>3. Propriété intellectuelle</h2>
      <p>
        Tous les contenus présents sur cette application (textes, images, code)
        sont protégés par les droits d'auteur et ne peuvent être reproduits sans
        autorisation préalable.
      </p>

      <h2>4. Collecte et protection des données</h2>
      <p>
        Les données personnelles collectées lors de l'inscription sont utilisées
        uniquement pour le bon fonctionnement de l'application. Vous avez le
        droit d'accéder à vos données, de les rectifier, et de demander leur
        suppression.
      </p>

      <h2>5. Limitation de responsabilité</h2>
      <p>
        L'application est fournie en l'état, sans garantie d'aucune sorte. Nous
        ne saurions être tenus responsables des dommages résultant de
        l'utilisation de l'application ou de l'interprétation des informations
        qu'elle contient.
      </p>

      <h2>6. Modifications des CGU</h2>
      <p>
        Nous nous réservons le droit de modifier ces conditions d'utilisation à
        tout moment. Les utilisateurs seront informés de tout changement par
        notification via l'application.
      </p>

      <h2>7. Résiliation du compte</h2>
      <p>
        Vous pouvez à tout moment vous désinscrire de l'application. En cas de
        désinscription, toutes vos données personnelles seront définitivement
        supprimées.
      </p>
      <Footer />
    </div>
  );
};

export default Cgu;
