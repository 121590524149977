import React, { Component } from "react";

import LoginForm from "../components/auth/LoginForm";

export class Login extends Component {
  render() {
    return (
      <div>
        <LoginForm />
      </div>
    );
  }
}

export default Login;
