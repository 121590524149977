import axios from "axios";
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Footer from "../../components/Footer";
import HeaderAdmin from "../../components/admin/HeaderAdmin";
import Sidebar from "../../components/admin/SideBarAdmin";
// import ButtonAdd from "../../components/admin/buttons/ButtonAddAdmin";
import { Link } from "react-router-dom";
// import ButtonDelete from "../../components/admin/buttons/ButtonDeleteAdmin";
// import ButtonEdit from "../../components/admin/buttons/ButtonEditAdmin";
import { FaEdit, FaTrash } from "react-icons/fa";

const FoodsAdmin = () => {
  const [foods, setFoods] = useState([]);

  useEffect(() => {
    const fetchFoods = async () => {
      try {
        const foodsResponse = await axios.get(
          "https://caloriesc.com/api/foods"
        );

        setFoods(foodsResponse.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchFoods();
  }, []);

  const handleDelete = async (foodId) => {
    try {
      await axios.delete(`https://caloriesc.com/api/foods/${foodId}`);
      // Supprimer l'aliment
      setFoods(foods.filter((food) => food.id !== foodId));
    } catch (error) {
      console.error("Error deleting food:", error);
    }
  };
  return (
    <div className="containerAdminAll">
      <div className="containerAdmin">
        <HeaderAdmin />
        <div className="containerFoodSideBar">
          <div className="containerSideBarAdmin">
            <Sidebar />
          </div>
          <div className="containerFoodAdmin">
            <div className="btnAddAdmin">
              <Link to="/admin/addFoodAdmin/" className="btnAddFoodAdmin">
                <span className="addFoodAdmin">+</span>
              </Link>
            </div>
            <div className="row">
              {foods.map((food) => (
                <div className="cardAdmin" key={food.id}>
                  <Card className="mb-4">
                    <Card.Body className="cardFoodAdmin">
                      <Card.Title className="titleCardAdmin">
                        {food.nameFoods}
                      </Card.Title>
                      <p>
                        <strong>Gras :</strong> {food.fats}
                      </p>
                      <p>
                        <strong>Glucides :</strong> {food.carbohydrates}
                      </p>
                      <p>
                        <strong>Fibres :</strong> {food.fibers}
                      </p>
                      <p>
                        <strong>Proteines :</strong> {food.proteins}
                      </p>
                      <p>
                        <strong>Sel :</strong> {food.salts}
                      </p>
                      <p>
                        <strong>Pour 100gr</strong>
                      </p>
                      {/* <ButtonEdit go={`/admin/editFoodAdmin/${food.id}`} /> */}
                      <Link to={`/admin/editFoodAdmin/${food.id}`}>
                        <FaEdit className="editIconAdmin" />
                      </Link>
                      {/* <ButtonDelete onClick={() => handleDelete(food.id)} /> */}
                      <FaTrash
                        className="deleteIconAdmin"
                        onClick={() => handleDelete(food.id)}
                      />
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FoodsAdmin;
