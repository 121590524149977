import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
// import "../../styles/style.sccs";

function SidebarAdmin() {
  const [showLinks, setShowLinks] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setShowLinks(window.innerWidth > 660);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="containerSideBar">
      <div>
        <button className="navBurger" onClick={() => setShowLinks(!showLinks)}>
          <span className="burgerBar"></span>
        </button>
      </div>

      {showLinks && (
        <Nav defaultActiveKey="/home">
          <div className="navBar">
            <Link to="/admin">Accueil</Link>
          </div>
          <div className="menu-link">
            <Link to="/admin/foods">Aliments</Link>
          </div>
          <div className="menu-link">
            <Link to="/admin/sports">Sports</Link>
          </div>
        </Nav>
      )}
    </div>
  );
}

export default SidebarAdmin;
