import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import auth from "../services/token";

const PrivateRoute = ({ adminOnly, ...props }) => {
  const isLoggedIn = auth.isLoggedIn();
  const isAdmin = auth.loggedAdmin();

  if (adminOnly && (!isLoggedIn || !isAdmin)) {
    return <Navigate to="/home" replace />;
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet {...props} />;
};

export default PrivateRoute;
