import axios from "axios";
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Footer from "../../components/Footer";
import HeaderAdmin from "../../components/admin/HeaderAdmin";
import Sidebar from "../../components/admin/SideBarAdmin";
// import ButtonDelete from "../../components/admin/buttons/ButtonDeleteAdmin";
import { FaTrash } from "react-icons/fa";
const UsersAdmin = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersResponse = await axios.get(
          "https://caloriesc.com/api/users"
        );
        setUsers(usersResponse.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchUsers();
  }, []);

  const handleDelete = async (userId) => {
    try {
      await axios.delete(`https://caloriesc.com/api/users/${userId}`);
      // Supprimer l'utilisateur
      setUsers(users.filter((user) => user.id !== userId));
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };
  return (
    <div className="generalContainerUserAdmin">
      <div className="containerAdminAll">
        <div className="containerAdmin">
          <HeaderAdmin />
          <div className="containerUserSideBar">
            <div className="containerSideBarAdmin">
              <Sidebar />
            </div>

            <div className="rowUserAdmin">
              <Card.Title className="titleUserAdmin">Utilisateurs</Card.Title>
              {users.map((user) => (
                <div className="cardUserAdmin" key={user.id}>
                  <Card className="mb-4">
                    <Card.Body>
                      <p>Pseudo : {user.pseudo}</p>
                      <p>Email : {user.email}</p>
                      {/* Afficher l'icône de suppression uniquement si l'utilisateur n'est pas administrateur */}
                      {user.role_id === 1 && (
                        <FaTrash
                          className="deleteIconAdmin"
                          onClick={() => handleDelete(user.id)}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default UsersAdmin;
