import axios from "axios";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import HeaderAdmin from "../../components/admin/HeaderAdmin";
import SiderBarAdmin from "../../components/admin/SideBarAdmin";
import auth from "../../services/token";
const AddFoodAdmin = () => {
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState({});

  const [nameFoods, setNameFoods] = useState("");
  const [fats, setFats] = useState("");
  const [carbohydrates, setCarbohydrate] = useState("");
  const [fibers, setFibers] = useState("");
  const [proteins, setProteins] = useState("");
  const [salts, setSalts] = useState("");

  const addFoods = async (e) => {
    e.preventDefault();
    // Vérifier si l'utilisateur est un administrateur
    if (!auth.loggedAdmin()) {
      alert("Vous n'avez pas la permission d'ajouter un aliment.");
      auth.logout();
      navigate("/login");
      return;
    }
    const formData = new FormData();

    formData.append("nameFoods", nameFoods);
    formData.append("fats", fats);
    formData.append("carbohydrates", carbohydrates);
    formData.append("fibers", fibers);
    formData.append("proteins", proteins);
    formData.append("salts", salts);

    try {
      const token = auth.getToken();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.post(`https://caloriesc.com/api/foods`, formData, config);
      navigate("/admin/foods");
    } catch (error) {
      console.log("Error response:", error.response);
      if (error.response) {
        if (error.response.status === 422) {
          setValidationError(error.response.data.errors);
        } else {
          // Afficher une erreur plus détaillée si l'API retourne une autre erreur
          alert("Une erreur est survenue : " + error.response.data.message);
        }
      } else {
        alert("Une erreur est survenue : " + error.message);
      }
    }
  };
  return (
    <div className="generalContenaireAddFoodAdmin">
      <div className="containerAdminAll">
        <div className="containerAdmin">
          <HeaderAdmin />
        </div>
        <div className="containerAddFoodsAdmin">
          <div className="containerSideBarAdmin">
            <SiderBarAdmin />
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-6">
              <div className="card mt-5">
                <div className="card-body ">
                  <h4 className="card-title">Ajout d'un aliment</h4>
                  <hr />
                  <div className="form-wrapper">
                    {Object.keys(validationError).length > 0 && (
                      <div className="row">
                        <div className="col-12">
                          <div className="alert alert-danger">
                            <ul className="mb-0">
                              {Object.entries(validationError).map(
                                ([key, value]) => (
                                  <li key={key}>{value}</li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}

                    <Form onSubmit={addFoods}>
                      <Row>
                        <Col>
                          <Form.Group controlId="nameFoods">
                            <Form.Label>Nom</Form.Label>
                            <Form.Control
                              type="text"
                              value={nameFoods}
                              onChange={(event) => {
                                setNameFoods(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group controlId="fats">
                            <Form.Label>Gras : </Form.Label>
                            <Form.Control
                              type="text"
                              value={fats}
                              onChange={(event) => {
                                setFats(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group controlId="carbohydrates">
                            <Form.Label>Glucides : </Form.Label>
                            <Form.Control
                              type="text"
                              value={carbohydrates}
                              onChange={(event) => {
                                setCarbohydrate(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group controlId="fibers">
                            <Form.Label>Fibres : </Form.Label>
                            <Form.Control
                              type="text"
                              value={fibers}
                              onChange={(event) => {
                                setFibers(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group controlId="proteins">
                            <Form.Label>Proteines</Form.Label>
                            <Form.Control
                              type="text"
                              value={proteins}
                              onChange={(event) => {
                                setProteins(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group controlId="salts">
                            <Form.Label>Sel</Form.Label>
                            <Form.Control
                              type="text"
                              value={salts}
                              onChange={(event) => {
                                setSalts(event.target.value);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Button
                        className="btnCreateFoodAdmin"
                        size="lg"
                        block="block"
                        type="submit"
                      >
                        Créer
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AddFoodAdmin;
