import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import EditUser from "./pages/utilisateur/users/EditUser";
import Login from "./pages/auth/Login";
import Admin from "./pages/admin/Admin";
import FoodsAdmin from "./pages/admin/foods/FoodsAdmin";
import AddFoodAdmin from "./pages/admin/foods/AddFoodAdmin";
import EditFoodAdmin from "./pages/admin/foods/EditFoodAdmin";
import SportsAdmin from "./pages/admin/sports/SportsAdmin";
import AddSportAdmin from "./pages/admin/sports/AddSportAdmin";
import EditSportAdmin from "./pages/admin/sports/EditSportAdmin";
import UsersAdmin from "./pages/admin/users/UsersAdmin";
import Register from "./pages/auth/Register";
import AddMeal from "./pages/utilisateur/meals/AddMeal";
import EditMeal from "./pages/utilisateur/meals/EditMeal";
import EditUserActivity from "./pages/utilisateur/useractivity/EditUseractivity";
import PrivateRoute from "./pages/components/PrivateRoute";
import MentionsL from "./pages/MentionsL";
import Cgu from "./pages/Cgu";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/register" element={<Register />} />
        <Route path="/mentionsL" element={<MentionsL />} />
        <Route path="/cgu" element={<Cgu />} />
        {/* Routes protégées */}
        <Route element={<PrivateRoute />}>
          <Route path="/home" element={<Home />} />
          <Route path="/editUser/:userId" element={<EditUser />} />
          <Route
            path="/editUserActivity/:userActivityId"
            element={<EditUserActivity />}
          />

          {/* Routes administratives protégées */}

          <Route element={<PrivateRoute adminOnly />}>
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/foods" element={<FoodsAdmin />} />
            <Route path="/admin/addFoodAdmin" element={<AddFoodAdmin />} />
            <Route
              path="/admin/editFoodAdmin/:foodId"
              element={<EditFoodAdmin />}
            />
            <Route path="/admin/sports" element={<SportsAdmin />} />
            <Route path="/admin/addSportAdmin" element={<AddSportAdmin />} />
            <Route
              path="/admin/editSportAdmin/:sportId"
              element={<EditSportAdmin />}
            />
            <Route path="/admin/users" element={<UsersAdmin />} />
          </Route>
        </Route>

        <Route path="/addMeal/:typeMeal" element={<AddMeal />} />
        <Route path="/editMeal/:mealId" element={<EditMeal />} />
      </Routes>
    </Router>
  );
}

export default App;
