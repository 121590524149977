import React from "react";

import Header from "./components/Header";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
const MentionsL = () => {
  return (
    <div className="containerMentionL">
      <Header />
      <Navigation />
      <h1>Mentions Légales</h1>

      <h2>1. Propriétaire du site</h2>
      <p>
        <strong>Nom : </strong>Macaire Jennifer <br />
        <strong>Email : </strong> contact.macaire@gmail.com <br />
      </p>

      <h2>2. Hébergeur du site</h2>
      <p>
        <strong>Nom : </strong> Hostinger <br />
        <strong>Adresse : </strong> Hostinger International Ltd. 61 Lordou
        Vironos Street 6023 Larnaca, Chypre <br />
        <strong>Site Web : </strong>{" "}
        <a href="https://www.hostinger.com/">https://www.hostinger.com</a>
      </p>

      <h2>3. Description du site</h2>
      <p>
        Ce site web est un projet de formation réalisé par Macaire Jennifer dans
        le cadre de l'apprentissage du développement web. Il s'agit d'un exemple
        de projet utilisé pour démontrer mes compétences techniques aux
        recruteurs et aux potentiels employeurs.
      </p>

      <h2>4. Propriété intellectuelle</h2>
      <p>
        Les contenus (textes, logo, code, etc.) présentés sur ce site sont la
        propriété de Macaire Jennifer, sauf mention contraire. Toute
        reproduction ou utilisation des contenus sans autorisation est
        interdite.
      </p>

      <h2>5. Collecte de données personnelles</h2>
      <p>
        Lors de l'inscription sur ce site, les informations suivantes sont
        collectées et enregistrées dans notre base de données :
      </p>
      <ul>
        <li>Nom d'utilisateur</li>
        <li>Adresse e-mail</li>
        <li>Mot de passe (crypté)</li>
        <li>Taille</li>
        <li>Poids</li>
        <li>Age</li>
        <li>Sexe</li>
        <li>Pseudo</li>
        <li>Objectif</li>
        <li>Activité</li>
      </ul>

      <p>
        Ces données sont collectées dans le but de permettre l'accès aux
        fonctionnalités du site, telles que la gestion de votre compte et
        l'accès à des services réservés aux utilisateurs inscrits.
      </p>

      <p>
        Vous avez le droit d'accéder à vos données personnelles, de les
        rectifier, de les supprimer ou de les exporter. Pour exercer ces droits,
        vous pouvez nous contacter à l'adresse suivante :{" "}
        <strong>contact.macaire@gmail.com</strong>
      </p>

      <p>
        Si vous choisissez de vous désinscrire, toutes les données personnelles
        associées à votre compte seront définitivement supprimées de notre base
        de données. Cette opération est irréversible et vise à garantir le
        respect de votre droit à l'oubli.
      </p>

      <p>
        Nous prenons toutes les mesures nécessaires pour protéger vos données
        personnelles contre tout accès non autorisé, divulgation, altération ou
        destruction. Le mot de passe est stocké de manière sécurisée en
        utilisant un algorithme de hachage.
      </p>

      <h2>6. Cookies</h2>
      <p>
        Ce site utilise n'utilise pas des cookies. Les cookies éventuellement
        utilisés sont uniquement destinés à améliorer l'expérience utilisateur
        et ne sont pas utilisés à des fins publicitaires.
      </p>

      <h2>7. Limitation de responsabilité</h2>
      <p>
        Les informations présentes sur ce site sont fournies à titre indicatif.
        Macaire Jennifer ne saurait être tenu responsable d'éventuelles erreurs
        ou omissions, ni des conséquences de l'utilisation des informations
        fournies.
      </p>

      <h2>8. Crédits</h2>
      <p>
        Certains éléments graphiques ou autres contenus peuvent provenir de
        ressources libres de droits ou sous licence, comme les "FontAwesome".
      </p>

      <h2>9. Contact</h2>
      <p>
        Pour toute question concernant ce site ou son contenu, vous pouvez
        contacter Macaire Jennifer à l'adresse suivante :{" "}
        <strong>contact.macaire@gmail.com</strong>
      </p>
      <Footer />
    </div>
  );
};

export default MentionsL;
