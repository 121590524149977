import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import auth from "../services/token";
import TraitOrange from "./TraitOrange";

function Navigation() {
  const userId = auth.getDecodedToken().sub;
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.logout();
    // window.location.reload();
    navigate("/");
  };
  ///////////////////// Burger
  const [showLinks, setShowLinks] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setShowLinks(window.innerWidth > 660);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  /////////////////////// Delete User /////////////////////////////////////////
  const handleDeleteUser = async () => {
    const userId = auth.getDecodedToken().sub;
    try {
      // Suppression de l'utilisateur par ID
      await axios.delete(`https://caloriesc.com/api/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
        },
      });

      // Stocker le message de succès dans localStorage
      localStorage.setItem(
        "deleteSuccessMessage",
        "Votre compte a été supprimé avec succès."
      );

      // Déconnexion
      auth.logout();
      // window.location.reload();
      navigate("/");
    } catch (error) {
      console.error("Erreur lors de la suppression du compte", error);
      // Stocker le message d'erreur dans localStorage
      localStorage.setItem(
        "deleteErrorMessage",
        "Erreur lors de la suppression du compte."
      );
      // Déconnexion
      auth.logout();
      window.location.reload();
      navigate("/");
    }
  };

  ////////////////////////////////////////////////////////////
  return (
    <div className="containerNavigation">
      <div>
        <button className="navBurger" onClick={() => setShowLinks(!showLinks)}>
          <span className="burgerBar"></span>
        </button>
      </div>

      {/* Affichez les liens du menu uniquement si showLinks est vrai */}
      {showLinks && (
        <>
          <div className="menu-link">
            <Link to="/home">Accueil</Link>
            <TraitOrange width="78px" height="2px" margin="10px 0" />
          </div>

          <div className="menu-link">
            <Link to={`/editUser/${userId}`}>Profil</Link>
            <TraitOrange width="53px" height="2px" margin="10px 0" />
          </div>
          <div className="menu-link">
            {/* Ce déconnecter */}
            <Link to="/" onClick={handleLogout}>
              Se déconnecter
            </Link>
            <TraitOrange width="158px" height="2px" margin="10px 0" />
          </div>

          {auth.isLoggedIn && auth.getExpiryTime() && auth.loggedAdmin() && (
            <div className="menu-link">
              <Link to="/admin" className="nav-link">
                Admin
              </Link>
              <TraitOrange width="70px" height="2px" margin="10px 0" />
            </div>
          )}

          <div className="menu-link">
            <Link to="/" onClick={handleDeleteUser}>
              Se désinscrire
            </Link>
            <TraitOrange width="135px" height="2px" margin="10px 0" />
          </div>
        </>
      )}
    </div>
  );
}

export default Navigation;
