import axios from "axios";
import { useState } from "react";
// import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
// import Form from "react-bootstrap/Form";
import { Button, Col, Form, Row } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { Controller, useForm } from "react-hook-form";
// import { AiOutlineEye, AiTwotoneEyeInvisible } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import TraitOrange from "../TraitOrange";
import HeaderAuth from "./HeaderAuth";
function RegisterForm() {
  document.title = "Inscription au site";

  let navigate = useNavigate();

  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const pseudo = watch("pseudo", "");
  const email = watch("email", "");
  const password = watch("password", "");
  const sexe = watch("sexe", "");
  const age = watch("age", "");
  const size = watch("size", "");
  const weight = watch("weight", "");
  const objective = watch("objective", "");
  const activity = watch("activity", "");
  // const role_id = watch("role_id", "1");

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = (e) => registerForm();

  let registerForm = async () => {
    try {
      // console.log("Valeurs des champs :", email, pseudo);
      let formData = new FormData();
      formData.append("pseudo", pseudo);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("sexe", sexe);
      formData.append("age", age);
      formData.append("size", size);
      formData.append("weight", weight);
      formData.append("objective", objective);
      formData.append("activity", activity);
      // formData.append("role_id", role_id);

      let res = await axios.post(
        "https://caloriesc.com/api/register",
        formData,

        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      console.log("Réponse reçue:", res);
      if (res.status === 200) {
        localStorage.setItem("access_token", res.data.token);

        navigate("/", { replace: true });
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setError("Email déjà utilisé.");
      } else {
        setError("Une erreur s'est produite lors de l'inscription.");
      }
    }
  };
  console.log(registerForm);
  return (
    <div className="containerRegister">
      <HeaderAuth />

      <Card.Title className="titleRegister">INSCRIPTION</Card.Title>
      <TraitOrange width="200px" height="2px" margin="14px auto 30px" />
      <div className="formRegister">
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {/*********************************************** section email ********************************************************/}
            <Form.Group controlId="formBasicEmail">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: "Adresse email obligatoire" }}
                render={({ field }) => (
                  <Form.Control
                    type="email"
                    placeholder="adresse email"
                    {...field}
                    autoComplete="email"
                  />
                )}
              />
              {errors.email && (
                <Form.Text className="text-danger">
                  {errors.email.message}
                </Form.Text>
              )}
            </Form.Group>
            {/****************************************** section password ********************************************************/}
            <Form.Group controlId="formPassword">
              <InputGroup className="inputEmail">
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Mot de passe est obligatoire",
                    minLength: {
                      value: 8,
                      message: "Longueur minimale de 8 caractères",
                    },
                    pattern: {
                      value:
                        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#:$%^&])/,
                      message:
                        "Le mot de passe doit contenir une minuscule, une majuscule, un chiffre et un caractère spécial",
                    },
                  }}
                  render={({ field }) => (
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Mot de passe"
                      {...field}
                      autoComplete="new-password"
                    />
                  )}
                />
              </InputGroup>
              {errors.password && (
                <Form.Text className="text-danger">
                  {errors.password.message}
                </Form.Text>
              )}
            </Form.Group>
            {/************************************** section sexe ********************************************************/}

            <Form.Group className="sexeHF">
              <Row>
                <Col>
                  <Controller
                    name="sexe"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Form.Check
                        type="radio"
                        id="homme"
                        label="Homme"
                        value="homme"
                        checked={field.value === "homme"}
                        onChange={() => field.onChange("homme")}
                      />
                    )}
                  />
                </Col>
                <Col>
                  <Controller
                    name="sexe"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Form.Check
                        type="radio"
                        id="femme"
                        label="Femme"
                        value="femme"
                        checked={field.value === "femme"}
                        onChange={() => field.onChange("femme")}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Form.Group>
            {/*************************************************** section age ********************************************************/}
            <Form.Group className="ageRegister">
              <Controller
                name="age"
                control={control}
                defaultValue=""
                rules={{
                  required: "Âge obligatoire",
                  pattern: {
                    value: /^[0-9]+(\.[0-9]{1,2})?$/,
                    message: "Seuls les nombres sont autorisés",
                  },
                }}
                render={({ field }) => (
                  <div>
                    <Form.Control type="text" placeholder="Âge" {...field} />
                    {errors.age && (
                      <Form.Text className="text-danger">
                        {errors.age.message}
                      </Form.Text>
                    )}
                  </div>
                )}
              />
            </Form.Group>
            {/************************************************* section size ********************************************************/}
            <Form.Group className="sizeRegister">
              <Row>
                <Col>
                  <Controller
                    name="size"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Poids obligatoire",
                      pattern: {
                        value: /^[0-9]+(\.[0-9]{1,2})?$/,
                        message: "Seuls les nombres sont autorisés",
                      },
                    }}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        placeholder=" Taille"
                        {...field}
                      />
                    )}
                  />
                  {errors.size && (
                    <Form.Text className="text-danger">
                      {errors.size.message}
                    </Form.Text>
                  )}
                </Col>
                {/*********************************************** section weight ********************************************************/}
                <Col>
                  <Controller
                    name="weight"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Poids obligatoire",
                      pattern: {
                        value: /^[0-9]+(\.[0-9]{1,2})?$/,
                        message: "Seuls les nombres sont autorisés",
                      },
                    }}
                    render={({ field }) => (
                      <Form.Control
                        type="text"
                        placeholder=" Poids"
                        {...field}
                      />
                    )}
                  />
                  {errors.weight && (
                    <Form.Text className="text-danger">
                      {errors.weight.message}
                    </Form.Text>
                  )}
                </Col>
              </Row>
            </Form.Group>
            {/****************************************** section objective ********************************************************/}
            <Form.Group>
              <Form.Label>Votre objectif</Form.Label>
              <Row className="objectiveRegister">
                <Col className="lossRegister">
                  <Controller
                    name="objective"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Form.Check
                        type="radio"
                        id="perteDePoids"
                        label="Perte De Poids"
                        value="perteDePoids"
                        checked={field.value === "perteDePoids"}
                        onChange={() => field.onChange("perteDePoids")}
                      />
                    )}
                  />
                </Col>
                <Col className="stabilityRegister">
                  <Controller
                    name="objective"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Form.Check
                        type="radio"
                        id="stabiliteDuPoids"
                        label="Stabilité Du Poids"
                        value="stabiliteDuPoids"
                        checked={field.value === "stabiliteDuPoids"}
                        onChange={() => field.onChange("stabiliteDuPoids")}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Form.Group>

            {/*********************************************** section activity ********************************************************/}
            <Form.Group>
              <Form.Label>Votre activité de base</Form.Label>
              <Row className="activityRegister">
                <Col className="noActiveregister">
                  <Controller
                    name="activity"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Form.Check
                        type="radio"
                        id="pasActif"
                        label="Pas Actif"
                        value="pasActif"
                        checked={field.value === "pasActif"}
                        onChange={() => field.onChange("pasActif")}
                      />
                    )}
                  />
                </Col>
                <Col className="noVeryActiveRegister">
                  <Controller
                    name="activity"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Form.Check
                        type="radio"
                        id="peuActif"
                        label="Peu Actif"
                        value="peuActif"
                        checked={field.value === "peuActif"}
                        onChange={() => field.onChange("peuActif")}
                      />
                    )}
                  />
                </Col>
                <Col className="activeRegister">
                  <Controller
                    name="activity"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Form.Check
                        type="radio"
                        id="actif"
                        label="Actif"
                        value="actif"
                        checked={field.value === "actif"}
                        onChange={() => field.onChange("actif")}
                      />
                    )}
                  />
                </Col>
              </Row>
            </Form.Group>
            {/*********************************************** section pseudo ********************************************************/}
            <Form.Group className="pseudoregister">
              <Controller
                name="pseudo"
                control={control}
                defaultValue=""
                rules={{ required: "Pseudo obligatoire" }}
                render={({ field }) => (
                  <Form.Control
                    type="text"
                    placeholder="Votre pseudo"
                    {...field}
                    autoComplete="username"
                  />
                )}
              />
              {errors.pseudo && (
                <Form.Text className="text-danger">
                  {errors.pseudo.message}
                </Form.Text>
              )}
            </Form.Group>
            <Button type="submit" className=" mt-3 createRegister">
              Créer un compte
            </Button>

            <div>
              <Link to="/" className="btnLoginRegister">
                Se connecter
              </Link>
            </div>
          </Form>

          {error && <Form.Text className="text-danger">{error}</Form.Text>}
        </Card.Body>
      </div>
    </div>
  );
}

export default RegisterForm;
