import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Navigation from "../../components/Navigation";

const EditMeal = () => {
  const { mealId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [validationError, setValidationError] = useState({});
  const [quantity, setQuantity] = useState("");
  const [calories, setCalories] = useState("");
  const [foods, setFoods] = useState([]);
  const [food_id, setFoodId] = useState("");

  //chargement de foods avant meals
  useEffect(() => {
    const fetchData = async () => {
      try {
        await getFoods();
        await getMeal();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getMeal = async () => {
    try {
      const response = await axios.get(
        `https://caloriesc.com/api/meals/${mealId}`
      );
      const { quantity, calories, food_id } = response.data;
      setQuantity(quantity);
      setCalories(calories);
      setFoodId(food_id);
    } catch (error) {
      console.error("Error fetching meal:", error);
    }
  };

  const getFoods = async () => {
    try {
      const response = await axios.get("https://caloriesc.com/api/foods");
      setFoods(response.data);
    } catch (error) {
      console.error("Error fetching foods:", error);
    }
  };

  const handleChange = (event) => {
    if (event.target.id === "foods") {
      setFoodId(event.target.value);
    } else if (event.target.id === "quantity") {
      setQuantity(event.target.value);
    }
    calculateCalories(); // Appel de calculateCalories à chaque changement
  };

  const calculateCalories = async () => {
    try {
      const response = await axios.get(
        `https://caloriesc.com/api/foods/${food_id}`
      );
      const selectedFood = response.data;

      const fats = selectedFood.fats;
      const carbohydrates = selectedFood.carbohydrates;
      const proteins = selectedFood.proteins;
      const calculCalorieFats = fats * 9;
      const calculCalorieCarbohydrates = carbohydrates * 4;
      const calculCalorieProteins = proteins * 4;
      const totalCalorie =
        calculCalorieFats + calculCalorieCarbohydrates + calculCalorieProteins;
      let calculatedCalories = (totalCalorie * parseInt(quantity)) / 100;
      calculatedCalories = Math.round(calculatedCalories);

      setCalories(calculatedCalories);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des détails de l'aliment :",
        error
      );
    }
  };

  const updateMeal = async (e) => {
    e.preventDefault();
    await calculateCalories();

    const formData = new FormData();
    formData.append("_method", "PATCH");
    formData.append("quantity", quantity);
    formData.append("calories", calories);
    formData.append("food_id", food_id);

    try {
      await axios.post(`https://caloriesc.com/api/meals/${mealId}`, formData);
      navigate("/home");
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setValidationError(error.response.data.errors);
      } else {
        console.error("Error updating meals:", error);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Afficher un indicateur de chargement
  }

  return (
    <div className="generalContainerEditMeal">
      <div className="containerEditMeal">
        <Header />
        <Navigation />
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-6">
            <div className="card mt-5">
              <div className="card-body">
                <h4 className="titleEditMeal">Modifier un repas</h4>
                <hr />
                <div className="form-wrapper">
                  {Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {Object.entries(validationError).map(
                              ([key, value]) => (
                                <li key={key}>{value}</li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  <Form onSubmit={updateMeal}>
                    <Row>
                      <Col>
                        <Form.Label>Quantité</Form.Label>
                        <Form.Control
                          type="text"
                          value={quantity}
                          onChange={handleChange}
                          id="quantity"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Control
                          as="select"
                          value={food_id}
                          onChange={handleChange}
                          id="foods"
                        >
                          <option value="">
                            Veuillez sélectionner un aliment
                          </option>
                          {foods.map((food) => (
                            <option key={food.id} value={food.id}>
                              {food.nameFoods}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="calories">
                          <Form.Label>Calories</Form.Label>
                          <p>{calories}</p>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      className="btnEditMeal"
                      size="lg"
                      block="block"
                      type="submit"
                    >
                      Modifier
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EditMeal;
