import React from "react";
import { Nav } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import "../../../style/admin.scss";

function HeaderAdmin() {
  return (
    <div className="containerHeaderAdmin">
      <Nav defaultActiveKey="/home">
        <Link to="/home">
          <img
            src="/images/logo3.png"
            alt="Logo de l'application"
            className="imgAdmin"
          />
        </Link>
        <Card.Title className="titleHeaderAdmin">Dashboard</Card.Title>
      </Nav>
    </div>
  );
}

export default HeaderAdmin;
