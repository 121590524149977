import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Navigation from "../../components/Navigation";
import auth from "../../services/token";
import { isMobile } from "react-device-detect";
const EditUser = () => {
  // Récupérez userId à partir des paramètres d'URL
  const { userId } = useParams();

  const navigate = useNavigate();

  const [validationError, setValidationError] = useState({});

  const [pseudo, setPseudo] = useState("");
  const [sexe, setSexe] = useState("");
  const [age, setAge] = useState("");
  const [size, setSize] = useState("");
  const [weight, setWeight] = useState("");
  const [objective, setObjective] = useState("");
  const [activity, setActivity] = useState("");

  useEffect(() => {
    if (isMobile) {
      // Logique spécifique aux mobiles
      window.location.reload(); // ou une autre action
    } else {
      // Logique pour les ordinateurs de bureau
      getUser();
    }
  }, []);

  const getUser = async () => {
    try {
      const userIdFromToken = auth.getDecodedToken().sub; // ID de l'utilisateur connecté
      const userIdFromUrl = userId; // ID de l'utilisateur à partir de l'URL

      // Comparaison des IDs
      if (userIdFromToken !== userIdFromUrl) {
        navigate("/home"); // Redirige vers la page d'accueil si les IDs ne correspondent pas
        return; // Sortir de la fonction
      }

      const response = await axios.get(
        `https://caloriesc.com/api/users/${userIdFromUrl}`
      );

      // Vérifie si la réponse contient bien l'utilisateur
      if (response.data && response.data.user) {
        setPseudo(response.data.user.pseudo);
        setSexe(response.data.user.sexe);
        setAge(response.data.user.age);
        setSize(response.data.user.size);
        setWeight(response.data.user.weight);
        setObjective(response.data.user.objective);
        setActivity(response.data.user.activity);
      } else {
        console.error("User data not found in response");
        navigate("/home"); // Redirige si aucune donnée d'utilisateur trouvée
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      navigate("/home"); // Redirige en cas d'erreur
    }
  };

  const updateUser = async (e) => {
    e.preventDefault();

    const formData = {
      pseudo: pseudo,
      sexe: sexe,
      age: age,
      size: size,
      weight: weight,
      objective: objective,
      activity: activity,
    };

    try {
      await axios.put(`https://caloriesc.com/api/users/${userId}`, formData);

      navigate("/home");
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setValidationError(error.response.data.errors);
      } else {
        console.error("Error updating user:", error);
      }
    }
  };

  return (
    <div className="generalContainerEditUser">
      <div className="containerEditUser">
        <Header />
        <Navigation />
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-6">
            <div className="card mt-5">
              <Card.Title className="titleUser">Profil</Card.Title>
              <div className="card-body">
                <hr />
                <div className="form-wrapper">
                  {Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {Object.entries(validationError).map(
                              ([key, value]) => (
                                <li key={key}>{value}</li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Formulaire pour modifier les données de l'utilisateur */}
                  <Form onSubmit={updateUser}>
                    <Row>
                      <Col>
                        <Form.Group controlId="pseudo">
                          <Form.Label>Pseudo</Form.Label>
                          <p>{pseudo}</p>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="age">
                          <Form.Label>Age</Form.Label>
                          <Form.Control
                            type="number"
                            value={age}
                            onChange={(event) => setAge(event.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="size">
                          <Form.Label>Taille</Form.Label>
                          <Form.Control
                            type="number"
                            value={size}
                            onChange={(event) => {
                              console.log(event);
                              setSize(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="weight">
                          <Form.Label>Poids</Form.Label>
                          <Form.Control
                            type="number"
                            value={weight}
                            onChange={(event) => setWeight(event.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {/****************************************** section objective ********************************************************/}

                    <Row>
                      <Col>
                        <Form.Label>Objectif</Form.Label>
                        <Form.Group>
                          <Form.Check
                            type="radio"
                            id="perteDePoids"
                            label="Perte De Poids"
                            value="perteDePoids"
                            checked={objective === "perteDePoids"}
                            onChange={(event) => {
                              console.log(event);
                              setObjective(event.target.value);
                            }}
                          />
                          <Form.Check
                            type="radio"
                            id="stabiliteDuPoids"
                            label="Stabilité Du Poids"
                            value="stabiliteDuPoids"
                            checked={objective === "stabiliteDuPoids"}
                            onChange={(event) =>
                              setObjective(event.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Label>Activité</Form.Label>
                        <Form.Group>
                          <Form.Check
                            type="radio"
                            id="pasActif"
                            label="Pas Actif"
                            value="pasActif"
                            checked={activity === "pasActif"}
                            onChange={(event) =>
                              setActivity(event.target.value)
                            }
                          />
                          <Form.Check
                            type="radio"
                            id="peuActif"
                            label="Peu Actif"
                            value="peuActif"
                            checked={activity === "peuActif"}
                            onChange={(event) =>
                              setActivity(event.target.value)
                            }
                          />
                          <Form.Check
                            type="radio"
                            id="actif"
                            label="Actif"
                            value="actif"
                            checked={activity === "actif"}
                            onChange={(event) =>
                              setActivity(event.target.value)
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      className="btnEditUser"
                      size="lg"
                      block="block"
                      type="submit"
                    >
                      Modifier
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EditUser;
