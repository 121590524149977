import axios from "axios";
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import HeaderAdmin from "../../components/admin/HeaderAdmin";
import Sidebar from "../../components/admin/SideBarAdmin";
// import ButtonEdit from "../../components/admin/buttons/ButtonEditAdmin";
import { FaEdit, FaTrash } from "react-icons/fa";
const SportsAdmin = () => {
  const [sports, setSports] = useState([]);

  useEffect(() => {
    const fetchSports = async () => {
      try {
        const sportsResponse = await axios.get(
          "https://caloriesc.com/api/sports"
        );
        setSports(sportsResponse.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchSports();
  }, []);

  const handleDelete = async (sportId) => {
    try {
      await axios.delete(`https://caloriesc.com/api/sports/${sportId}`);
      // Supprimer le sport
      setSports(sports.filter((sport) => sport.id !== sportId));
    } catch (error) {
      console.error("Error deleting sport:", error);
    }
  };
  return (
    <div className="containerAdminAll">
      <div className="containerAdmin">
        <HeaderAdmin />
        <div className="containerFoodSideBar">
          <div className="containerSideBarAdmin">
            <Sidebar />
          </div>
          <div className="containerSportAdmin">
            <div className="btnAddAdmin">
              <Link to="/admin/addSportAdmin/" className="btnAddSportAdmin">
                <span className="addSportAdmin">+</span>
              </Link>
            </div>
            <div className="row">
              {sports.map((sport) => (
                <div className="cardSportAdmin" key={sport.id}>
                  <Card className="mb-4">
                    <Card.Body>
                      <Card.Title className="nameSports">
                        {sport.nameSports}
                      </Card.Title>
                      <p>Met : {sport.met}</p>
                      {/* <ButtonEdit go={`/admin/editSportAdmin/${sport.id}`} /> */}
                      <Link to={`/admin/editSportAdmin/${sport.id}`}>
                        <FaEdit className="editIconAdmin" />
                      </Link>
                      {/* <ButtonDelete onClick={() => handleDelete(sport.id)} /> */}
                      <FaTrash
                        className="deleteIconAdmin"
                        onClick={() => handleDelete(sport.id)}
                      />
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default SportsAdmin;
