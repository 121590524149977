import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Navigation from "../../components/Navigation";
import auth from "../../services/token";

const EditUseractivity = () => {
  const { userActivityId } = useParams();
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState({});
  const [duration, setDuration] = useState("");
  const [caloriesburned, setCaloriesburned] = useState("");
  const [sport_id, setSportId] = useState("");
  const [sports, setSports] = useState([]);
  const [loading, setLoading] = useState(true);

  const calculateResult = (weight, met, duration) => {
    return weight * (duration / 60) * met;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getSports();
        await getUseractivity();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (duration && sport_id) {
      calculateAndSetResult(sport_id, duration);
    }
  }, [duration, sport_id]);

  const calculateAndSetResult = (sport, duration) => {
    const userId = auth.getDecodedToken().sub;

    axios
      .get(`https://caloriesc.com/api/users/${userId}`)
      .then((response) => {
        const weight = response.data.user.weight;

        axios
          .get(`https://caloriesc.com/api/sports/${sport}`)
          .then((sportResponse) => {
            const met = sportResponse.data.met;

            // Calculer le résultat en utilisant la fonction calculateResult
            const result = calculateResult(weight, met, duration);
            setCaloriesburned(result);
          })
          .catch((error) => {
            console.error(
              "Erreur lors de la récupération du MET du sport :",
              error
            );
          });
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération du poids de l'utilisateur :",
          error
        );
      });
  };

  const getUseractivity = async () => {
    try {
      const response = await axios.get(
        `https://caloriesc.com/api/useractivity/${userActivityId}`
      );
      if (response.data.length > 0) {
        const { duration, caloriesburned, sport_id } = response.data[0];
        setDuration(duration);
        setCaloriesburned(caloriesburned);
        setSportId(sport_id);
      }
    } catch (error) {
      console.error("Error fetching userActivity:", error);
    }
  };

  const getSports = async () => {
    try {
      const response = await axios.get("https://caloriesc.com/api/sports");
      setSports(response.data);
    } catch (error) {
      console.error("Error fetching sports:", error);
    }
  };

  const handleChange = (event) => {
    setSportId(event.target.value);
  };

  const updateUseractivity = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("_method", "PATCH");
    formData.append("duration", duration);
    formData.append("caloriesburned", caloriesburned);
    formData.append("sports", sports);
    formData.append("sport_id", sport_id);

    try {
      await axios.post(
        `https://caloriesc.com/api/useractivity/${userActivityId}`,
        formData
      );
      navigate("/home");
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setValidationError(error.response.data.errors);
      } else {
        console.error("Error updating userActivity:", error);
      }
    }
  };
  if (loading) {
    return <div>Loading...</div>; // Vous pouvez remplacer par un spinner ou une autre indication de chargement
  }
  return (
    <div className="generalContainerEditUserActivity">
      <Header />
      <Navigation />
      <div className="containerEditUserActivity">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-6">
            <div className="card mt-5">
              <div className="card-body">
                <h4 className="titleEditUserActivity">Modifier une activité</h4>
                <hr />
                <div className="form-wrapper">
                  {Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {Object.entries(validationError).map(
                              ([key, value]) => (
                                <li key={key}>{value}</li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  <Form onSubmit={updateUseractivity}>
                    <Row>
                      <Col>
                        <Form.Group controlId="duration">
                          <Form.Label>Durée</Form.Label>
                          <Form.Control
                            type="text"
                            value={duration}
                            onChange={(event) => {
                              setDuration(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="useractivity">
                          <Form.Control
                            as="select"
                            value={sport_id}
                            onChange={handleChange}
                          >
                            <option value="">
                              Veuillez sélectionner un sport
                            </option>
                            {sports.map((sport) => (
                              <option key={sport.id} value={sport.id}>
                                {sport.nameSports}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="caloriesburned">
                          <Form.Label>Calories brûlées</Form.Label>
                          <p>{caloriesburned}</p>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      className="btnEditUserActivity"
                      size="lg"
                      block="block"
                      type="submit"
                    >
                      Modifier
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EditUseractivity;
