import React from "react";
import Footer from "../components/Footer";
import HeaderAdmin from "../components/admin/HeaderAdmin";
import SiderBarAdmin from "../components/admin/SideBarAdmin";

const Admin = () => {
  return (
    <div className="containerAdminAll">
      <div className="containerAdmin">
        <HeaderAdmin />
        <div className="containerSideBarAdmin">
          <SiderBarAdmin />
        </div>
        <div className="containerHomeAdmin"></div>
      </div>
      <Footer />
    </div>
  );
};

export default Admin;
