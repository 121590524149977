import React from "react";
import { Link } from "react-router-dom";

import "../../style/home.scss";

function Footer() {
  return (
    <div className="containerFooter">
      <div className="containerFooterHome">
        <Link to="/Cgu">
          <p>Conditions générales d'utilisation</p>
        </Link>
        <p>&copy; 2024 CaloriesC</p>
        <Link to="/MentionsL">
          <p>Mentions légales</p>
        </Link>
      </div>
    </div>
  );
}

export default Footer;
