import React, { Component } from "react";

import RegisterForm from "../components/auth/RegisterForm";

export class Register extends Component {
  render() {
    return (
      <div>
        <RegisterForm />
      </div>
    );
  }
}

export default Register;
